<template>
  <div class="relative">
    <div class="absolute top-20 md:top-50 left-2/4" style="z-index: 99">
      <div style="position: relative; left: -50%; top: -50%">
        <img
          src="/static/lif-logo-blanco.png"
          class="w-3/4 md:w-1/3 mx-auto my-auto"
          alt="Logo LIF"
        />
        <h1
          class="subtitle-text text-white text-center text-xl sm:text-2xl md:text-3xl xl:text-5xl"
        >
          Bienvenidos al registro de la Liga Internacional de Fútbol
        </h1>
        <h1
          class="md:flex justify-center items-center mt-3 subtitle-text text-white text-center text-sm sm:text-md md:text-lg xl:text-xl"
        >
          ¿Quieres registrar una academia?
          <span>
            <t-button
              class="mx-auto md:ml-2 mt-3 md:my-auto"
              @click="$router.push('/registro_academia')"
              >Haz clic aquí</t-button
            >
          </span>
        </h1>

        <h1
          class="mt-3 subtitle-text text-white text-center text-sm sm:text-md md:text-lg xl:text-xl"
        >
          Si eres un jugador o entrenador debes registrarte a través del link
          facilitado por tu academia
        </h1>
      </div>
    </div>

    <div class="hidden md:block w-full pb-4 absolute bottom-0">
      <p class="text-white text-center flex justify-center">
        Copyright © 2022 Ticktaps C.A.
      </p>
    </div>

    <carousel
      style="opacity: 0.3"
      :nav="false"
      :dots="false"
      class="carousel-card relative"
      :autoplay="true"
      :responsive="{
        0: { items: 1, nav: false }
      }"
    >
      <img src="/static/bg1.jpg" alt="LIF Home - Background 1" />
      <img src="/static/bg2.jpg" alt="LIF Home - Background 2" />
      <img src="/static/bg3.jpg" alt="LIF Home - Background 3" />
      <img src="/static/bg4.jpg" alt="LIF Home - Background 4" />
      <img src="/static/bg5.jpg" alt="LIF Home - Background 4" />
      <img src="/static/bg6.jpg" alt="LIF Home - Background 4" />
      <img src="/static/bg7.jpg" alt="LIF Home - Background 4" />
    </carousel>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel"
const notify = () => import("@/utils/notify.js")
export default {
  components: {
    carousel
  },
  computed: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style>
.carousel-card .owl-carousel .owl-item img {
  width: 100vw;
  object-fit: cover;
  height: 100vh;
}
.carousel-card .owl-nav {
  display: none;
}
</style>
